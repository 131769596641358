import { Button, Row } from "react-bootstrap";

const Cotizador1 = ({setStep}) => {

    const handleSubmit = () => {
        setStep(s => s+1)
    }

    return (
            <Row className="justify-content-center align-items-center min-vh-100">
                <div className="text-center">
                    <div className="fs-3 fw-bold">¡Hola!</div>
                    <div className="fs-5 mb-4">
                        Vamos a conseguir el mejor precio para tu mudanza
                    </div>
                    <div>
                        <Button onClick={handleSubmit} className="px-5 py-2 text-white fw-bold">
                            Comencemos
                        </Button>
                    </div>
                </div>
            </Row>
    );
}

export default Cotizador1;