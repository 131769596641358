import { Image } from "react-bootstrap";

const ArrowBack = ({ setStep }) => {
    const goBack = () => setStep(s => s - 1)

    return (
        <div className="position-absolute top-0 start-0 mt-4" >
            <Image fluid src='/back-arrow.png' style={{ height: '2rem', cursor: 'pointer' }} onClick={goBack} />
        </div>
    );
}

export default ArrowBack;