import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './custom.scss';

import Cotizacion from './Routes/Cotizacion';
import CotizacionMayor from './Routes/CotizacionMayor';
import Cotizador from './Routes/Cotizador';
import Parametros from './Routes/Parametros';

import { getCotizacion } from './functions';

import TagManager from 'react-gtm-module'

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports)

TagManager.initialize({gtmId: process.env.REACT_APP_GTAG_ID})

const router = createBrowserRouter([
  {
    path: "/",
    element: <Cotizador />,
  },
  {
    path: "/cotizacion/:id",
    element: <Cotizacion />,
    loader: getCotizacion
  },
  {
    path: "/parametros",
    element: <Parametros />
  },
  {
    path: "/mudanza",
    element: <CotizacionMayor />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

