import { Accordion } from "react-bootstrap";

const AccordionCustom = () => {
    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>1. ¿Por qué debo pagar una reserva?</Accordion.Header>
                <Accordion.Body className="mb-4">
                    Con una reserva de $25.000 aseguras la fecha disponible para tu mudanza.
                    Dejamos reservados los camiones necesarios, personal y comenzamos a organizar el trabajo.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>2. ¿Qué pasa si necesito cambiar de fecha?</Accordion.Header>
                <Accordion.Body className="mb-4">
                    Si necesitas cambiar de fecha, lo puedes hacer sin costo, siempre y cuando nos avises con 5 días de anticipación.
                    De otro modo, se pierde la reserva.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>3. ¿Qué Incluye la Mudanza Light?</Accordion.Header>
                <Accordion.Body className="mb-4">
                    La mudanza Light incluye el embalaje y traslado de muebles, electrodomésticos, televisores y cuadros.
                    No se incluye embalaje en cajas, pero si el traslado de cajas que tengas listas para llevar.
                    En la casa de destino, dejamos los muebles armados y las cajas distribuidas por sectores.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>4. ¿Qué Incluye la Mudanza Premium?</Accordion.Header>
                <Accordion.Body className="mb-4">
                    La Mudanza Premium incluye embalaje en cajas de loza, menaje de cocina, adornos, y ropa, según la cantidad de cajas que necesites.
                    Una vez en la casa de destino, dejamos los muebles instalados y las cajas distribuidas por sectores.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>5. ¿Qué Incluye la Mudanza Full?</Accordion.Header>
                <Accordion.Body className="mb-4">
                    La Mudanza Full, además del embalaje completo y traslado, incluye el desembalaje y orden de tus pertenencias en tu nueva casa. 
                    Además si lo necesitas, puedes solicitar el servicio de colgado de cuadros, televisores y lámparas. 
                    De esta forma puedes encargarte solo de darnos indicaciones mientras nosotros hacemos el trabajo.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>6. Cuál es la forma de pago?</Accordion.Header>
                <Accordion.Body className="mb-4">
                    Se paga un abono de $25.000 para asegurar la fecha de mudanza y el saldo pendiente, hasta el día anterior a la mudanza.
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default AccordionCustom;