import { useEffect, useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { es } from 'date-fns/locale'

import "react-datepicker/dist/react-datepicker.css";
import TagManager from "react-gtm-module";

const Cotizador2 = ({ setStep, input, setInput }) => {

    const [errors, setErrors] = useState({ fecha: !input.fecha })
    const [validar, setValidar] = useState(false)

    const [fecha, setFecha] = useState()

    const handleSubmit = (e) => {
        setValidar(true)
        if (Object.values(errors).filter(Boolean).length === 0) setStep(s => s + 1)
    }

    useEffect(() => {
        if (fecha) {
            setInput(i => ({ ...i, fecha: fecha.toISOString() }))
        }
        setErrors(e => ({ ...e, fecha: !fecha }))
    }, [fecha])

    useEffect(() => {
        TagManager.dataLayer({ dataLayer: { event: 'begin_checkout', ecommerce: { currency: 'CLP' } } })
    }, [])

    return (
        <Row className="justify-content-center align-items-center min-vh-100">
            <Form noValidate className="form-max">
                <Form.Group className="my-5">
                    <Form.Label>
                        En qué fecha necesitas tu mudanza?
                    </Form.Label>
                    <DatePicker
                        required
                        selected={fecha}
                        onChange={(d) => setFecha(d)}
                        className='form-control' dateFormat="dd MMMM yyyy" locale={es} calendarStartDay={1}
                        minDate={new Date()}
                        customInput={
                            <Form.Control type='text' name='fecha' isValid={validar && !errors.fecha} isInvalid={validar && errors.fecha} />
                        }
                    />
                    <Form.Control.Feedback type="invalid" tooltip>Escoje la fecha de la mudanza</Form.Control.Feedback>
                </Form.Group>
                <div className="text-center">
                    <Button onClick={handleSubmit} className="px-5 py-2 text-white fw-bold">
                        Continuar
                    </Button>
                </div>
            </Form>
        </Row>
    );
}

export default Cotizador2;