import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Button, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import DirForm from "../components/DirForm";

import { API, Auth } from "aws-amplify";
import { formatRut } from "../functions";

import DatePicker from "react-datepicker";
import { es } from 'date-fns/locale'
import Slider from "rc-slider";
import 'rc-slider/assets/index.css';


const marks = Array.from({ length: 6 }, (_, i) => (i + 1) * 20).reduce((a, v) => ({ ...a, [v]: <small>{v}m<sup>3</sup></small> }), {})


const CotizacionMayor = () => {
    const [signedIn, setSignedIn] = useState(false)
    const [loading, setLoading] = useState({ login: false, save: false })

    const [login, setLogin] = useState({ user: '', pwd: '' })
    const [cotizacion, setCotizacion] = useState({})
    const [error, setError] = useState(false)

    const [fecha, setFecha] = useState()
    const [rut, setRut] = useState('')

    const navigate = useNavigate();

    const validateUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser()
            if (user) setSignedIn(true)
        } catch (error) {
            console.log('validate error', error)
        }
    }

    const handleLoginChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setLogin(i => ({ ...i, [name]: value }))
    }

    const handleLogin = async () => {
        setLoading({ ...loading, login: true })
        try {
            const user = await Auth.signIn(login.user, login.pwd)
            // if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            //     await Auth.completeNewPassword(user, 'MudanzasMayor')
            // }
            validateUser()
        } catch (error) {
            console.log('login error', error)
        }
        setLoading({ ...loading, login: false })
    }

    const handleSubmit = async () => {
        setLoading({ ...loading, save: true })
        try {
            const { id } = await API.post('apid46427ec', '/cotizador', { body: { ...cotizacion, creado: 1 } })
            if (!!id) navigate(`/cotizacion/${id}`)
        } catch (error) {
            console.log('uploadData', error)
            setError(true)
        }
        setLoading({ ...loading, save: false })
    }

    const handleChangeOri = (place) => {
        setCotizacion(i => ({ ...i, dirOri: place.formatted_address, locOri: place.place_id }))
    }
    const handleChangeDes = (place) => {
        setCotizacion(i => ({ ...i, dirDes: place.formatted_address, locDes: place.place_id }))
    }

    const handleChangeSlider = (v) => {
        setCotizacion(i => ({ ...i, volumen: v }))
    }

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setCotizacion(i => ({ ...i, [name]: value }))
    }

    const handleChangeMult = (e) => {
        const name = e.target.name
        const value = e.target.value
        const newPrecio = {...cotizacion.precios, [name]: value}
        setCotizacion(i => ({ ...i, precios: newPrecio }))
    }
    const handleChangeCajas = (e) => {
        const value = e.target.value
        setCotizacion(i => ({ ...i, cajas: {premium: value, full: value} }))
    }

    useEffect(() => {
        if (fecha) {
            setCotizacion(i => ({ ...i, fecha: fecha.toISOString() }))
        }
    }, [fecha])

    useEffect(() => {
        if (rut) {
            setCotizacion(i => ({ ...i, rut: formatRut(rut) }))
        }
    }, [rut])

    useEffect(() => {
        validateUser()
    }, [])

    return (
        <>
            <Helmet>
                <title>Cotización</title>
            </Helmet>
            {!signedIn ?
                <Container >
                    <Row className="justify-content-center align-items-center min-vh-100">
                        <Form noValidate className="form-max">
                            <Form.Group className="my-3">
                                <FloatingLabel controlId="form-user" label="Email">
                                    <Form.Control required type="email" placeholder="Email" name="user" value={login.user} onChange={handleLoginChange} />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group className="my-3">
                                <FloatingLabel controlId="form-pwd" label="Contraseña">
                                    <Form.Control required type="password" placeholder="Contraseña" name="pwd" value={login.pwd} onChange={handleLoginChange} />
                                </FloatingLabel>
                            </Form.Group>
                            <div>
                                <Button onClick={handleLogin} disabled={!login.user || !login.pwd || loading.login} className="my-4 px-5 text-white fw-bold" >
                                    {loading.login ? 'Cargando...' : 'Login'}
                                </Button>
                            </div>
                        </Form>
                    </Row>
                </Container>
                :
                <Container>
                    <Row className="justify-content-center align-items-center py-5">
                        <Form noValidate className="form-max">
                            <div className="text-center fs-4">Nueva Cotización</div>

                            <Form.Group className="my-5">
                                <FloatingLabel label="Nombre" controlId="form-nombre">
                                    <Form.Control required name="nombre" value={cotizacion.nombre} onChange={handleChange} placeholder="Nombre" />
                                    <Form.Control.Feedback type="invalid" tooltip className="text-white">Ingresa tu nombre</Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group className="my-5">
                                <FloatingLabel label="RUT" controlId="form-rut">
                                    <Form.Control required name="rut" value={rut} onChange={r => setRut(r.target.value)} placeholder="12345678-9" />
                                    <Form.Control.Feedback type="invalid" tooltip className="text-white">Ingresa un RUT válido</Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group className="my-5">
                                <FloatingLabel label="Mail" controlId="form-mail">
                                    <Form.Control required type="email" name="mail" value={cotizacion.mail} onChange={handleChange} placeholder="Mail" />
                                    <Form.Control.Feedback type="invalid" tooltip className="text-white">Ingresa tu email con el formato email@email.com</Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group className="my-5">
                                <FloatingLabel label="Teléfono" controlId="form-tel">
                                    <Form.Control required type="tel" name="tel" value={cotizacion.tel} onChange={handleChange} placeholder="Teléfono" />
                                    <Form.Control.Feedback type="invalid" tooltip className="text-white">Ingresa tu teléfono</Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group className="my-5">
                                <FloatingLabel label="Comentarios" controlId="form-coment">
                                    <Form.Control as="textarea" name="coment" value={cotizacion.coment} onChange={handleChange} placeholder="Comentarios" />
                                </FloatingLabel>
                            </Form.Group>
                            <hr />

                            <Form.Group className="my-3">
                                <Form.Label column>Fecha mudanza</Form.Label>
                                <DatePicker
                                    required
                                    selected={fecha}
                                    onChange={(d) => setFecha(d)}
                                    className='form-control' dateFormat="dd MMMM yyyy" locale={es} calendarStartDay={1}
                                    minDate={new Date()}
                                    customInput={
                                        <Form.Control type='text' name='fecha' />
                                    }
                                />

                            </Form.Group>

                            <Form.Group className="my-5">
                                <FloatingLabel controlId="form-tipoVivOri" label="Tipo de vivienda de origen">
                                    <Form.Select required name="tipoVivOri" value={cotizacion.tipoVivOri} onChange={handleChange}>
                                        <option value="">...</option>
                                        <option value="casa">Casa</option>
                                        <option value="depaconAsc">Departamento con ascensor</option>
                                        <option value="depasinAsc">Departamento sin ascensor</option>
                                        <option value="bodega">Bodega</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group controlId="form-dirOri" className="my-5">
                                <FloatingLabel controlId="form-dirOri" label="Dirección de origen">
                                    {/* <Form.Control required ref={refOri} placeholder="Ingresa dirección" /> */}
                                    <DirForm handleChange={handleChangeOri} />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group className="my-5">
                                <FloatingLabel controlId="form-tipoVivDes" label="Tipo de vivienda de destino">
                                    <Form.Select required name="tipoVivDes" value={cotizacion.tipoVivDes} onChange={handleChange} >
                                        <option value="">...</option>
                                        <option value="casa">Casa</option>
                                        <option value="depaconAsc">Departamento con ascensor</option>
                                        <option value="depasinAsc">Departamento sin ascensor</option>
                                        <option value="bodega">Bodega</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" tooltip className="text-white">Selecciona el tipo de vivienda</Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group controlId="form-dirDes" className="my-5">
                                <FloatingLabel controlId="form-dirDes" label="Dirección de destino">
                                    {/* <Form.Control required ref={refDes} placeholder="Ingresa dirección" /> */}
                                    <DirForm handleChange={handleChangeDes} />
                                </FloatingLabel>
                            </Form.Group>
                            <hr />

                            <Form.Group className="mt-3 mb-5 pb-4">
                                <div className="position-relative">
                                    <Form.Label>Volumen</Form.Label>
                                    <div className="d-flex">
                                        <div className="small text-center" style={{ width: '20%' }}>{cotizacion.volumen || 0}m<sup>3</sup></div>
                                        <Slider min={0} max={120} step={5} marks={marks} value={cotizacion.volumen} onChange={handleChangeSlider} className="" />
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group className="my-5">
                                <FloatingLabel controlId="form-nroCam" label="N° de Camiones">
                                    <Form.Control required type="number" min={0} max={2} name="nroDorm" value={cotizacion.nroDorm} onChange={handleChange} />
                                </FloatingLabel>
                            </Form.Group>
                            <hr />

                            <div>Precios</div>
                            <Row>
                                <Col sm={4} className="my-2">
                                    <Form.Group>
                                        <FloatingLabel controlId="form-precio-light" label="Light">
                                            <Form.Control required type="number" min={0} name="light" value={cotizacion.precios?.light} onChange={handleChangeMult} />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4} className="my-2">
                                    <Form.Group>
                                        <FloatingLabel controlId="form-precio-light" label="Premium">
                                            <Form.Control required type="number" min={0} name="premium" value={cotizacion.precios?.premium} onChange={handleChangeMult} />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4} className="my-2">
                                    <Form.Group>
                                        <FloatingLabel controlId="form-precio-light" label="Full">
                                            <Form.Control required type="number" min={0} name="full" value={cotizacion.precios?.full} onChange={handleChangeMult} />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group className="my-5">
                                <FloatingLabel controlId="form-nroCajas" label="N° de Cajas">
                                    <Form.Control required type="number" min={0} max={240} step={10} name="premium" value={cotizacion.cajas?.premium} onChange={handleChangeCajas} />
                                </FloatingLabel>
                            </Form.Group>

                            <div className="text-center">
                                <Button onClick={handleSubmit} className="px-5 py-2 text-white fw-bold" >
                                    {loading.save ? 'Enviando...' : 'Enviar'}
                                </Button>
                                {!!error &&
                                    <div className="small">Ha ocurrido un error, intente nuevamente.</div>
                                }
                            </div>
                        </Form>
                    </Row>
                </Container>}
        </>
    );
}

export default CotizacionMayor;