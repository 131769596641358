import { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import { Button, Col, Container, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { createParametros, paramsBydate } from "../graphql/customQueries";


const superfKeys = {
    50: '30 a 50 m2', 60: '50 a 60 m2', 70: '60 a 70 m2', 80: '70 a 80 m2', 100: '80 a 100 m2', 120: '100 a 120 m2', 150: '120 a 150 m2', 180: '150 a 180 m2', 200: '180 a 200 m2', 250: '200 a 250 m2', 300: '250 a 300 m2'
}

const Parametros = () => {
    const [signedIn, setSignedIn] = useState(false)
    const [loading, setLoading] = useState({ login: false, form: true, save: false })

    const [input, setInput] = useState({ user: '', pwd: '' })
    const [params, setParams] = useState({})

    const validateUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser()
            if (user) setSignedIn(true)
        } catch (error) {
            console.log('validate error', error)
        }
    }

    const getData = async () => {
        setLoading({ ...loading, form: true })
        try {
            const { data: { paramsBydate: { items } }, errors } = await API.graphql({ query: paramsBydate, variables: { tipo: 1, limit: 1, sortDirection: 'DESC' }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            if (errors) throw errors
            setParams(items[0])
        } catch (error) {
            console.log('getData', error)
        }
        setLoading({ ...loading, form: false })
    }

    const updateData = async () => {
        setLoading({ ...loading, save: true })
        try {
            const { errors } = await API.graphql({ query: createParametros, variables: { input: params }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            if (errors) throw errors
        } catch (error) {
            console.log('updateData', error)
        }
        setLoading({ ...loading, save: false })
    }

    const handleLoginChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setInput(i => ({ ...i, [name]: value }))
    }

    const handleLogin = async () => {
        setLoading({ ...loading, login: true })
        try {
            const user = await Auth.signIn(input.user, input.pwd)
            // if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            //     await Auth.completeNewPassword(user, 'MudanzasMayor')
            // }
            validateUser()
        } catch (error) {
            console.log('login error', error)
        }
        setLoading({ ...loading, login: false })
    }

    const handleChange = (e, f) => {
        const name = e.target.name
        const value = e.target.value
        const oldObject = params[f]
        const newObject = { ...oldObject, [name]: value }
        setParams(p => ({ ...p, [f]: newObject }))
    }

    const handleTableChange = (e, superf) => {
        console.log(e, superf)
        const name = e.target.name
        const value = e.target.value
        const oldElem = params.superficie.find((item) => item.superf === superf)
        const newElem = {...oldElem, [name]: value}
        const result = params.superficie.map((item) => item.superf === superf ? newElem : item)
        setParams(p => ({ ...p, superficie: result }))
    }

    useEffect(() => {
        validateUser()
    }, [])

    useEffect(() => {
        console.log('signedin', signedIn)
        if (signedIn) getData()
    }, [signedIn])

    return (
        <>
            <Helmet>
                <title>Parámetros</title>
            </Helmet>
            {!signedIn ?
                <Container >
                    <Row className="justify-content-center align-items-center min-vh-100">
                        <Form noValidate className="form-max">
                            <Form.Group className="my-3">
                                <FloatingLabel controlId="form-user" label="Email">
                                    <Form.Control required type="email" placeholder="Email" name="user" value={input.user} onChange={handleLoginChange} />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group className="my-3">
                                <FloatingLabel controlId="form-pwd" label="Contraseña">
                                    <Form.Control required type="password" placeholder="Contraseña" name="pwd" value={input.pwd} onChange={handleLoginChange} />
                                </FloatingLabel>
                            </Form.Group>
                            <div>
                                <Button onClick={handleLogin} disabled={!input.user || !input.pwd || loading.login} className="my-4 px-5 text-white fw-bold" >
                                    {loading.login ? 'Cargando...' : 'Login'}
                                </Button>
                            </div>
                        </Form>
                    </Row>
                </Container>
                : !loading.form &&
                <Container>
                    <Row>
                        <div className="d-flex justify-content-between my-4">
                            <div className="fs-4">Parámetros</div>
                            <div>
                                <Button disabled={loading.save} onClick={updateData} className="px-5 text-white fw-bold">
                                    {loading.save ? 'Guardando...' : 'Guardar'}
                                </Button>
                            </div>
                        </div>
                        <Col md={6} lg={4} className="p-4">
                            <Form noValidate>
                                <div className="fs-5 border-bottom">Factor tipo de vivienda</div>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Bodega</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} step={0.01} name="bodega" value={params?.precioViv?.bodega} onChange={(e) => handleChange(e, 'precioViv')} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Casa</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} step={0.01} name="casa" value={params?.precioViv?.casa} onChange={(e) => handleChange(e, 'precioViv')} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Departamento con ascensor</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} step={0.01} name="depaconAsc" value={params?.precioViv?.depaconAsc} onChange={(e) => handleChange(e, 'precioViv')} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Departamento sin ascensor</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} step={0.01} name="depasinAsc" value={params?.precioViv?.depasinAsc} onChange={(e) => handleChange(e, 'precioViv')} />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col md={6} lg={4} className="p-4">
                            <Form noValidate>
                                <div className="fs-5 border-bottom">Precios x Km</div>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>{'< 150 km'}</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} name="r150" value={params?.precioKm?.r150} onChange={(e) => handleChange(e, 'precioKm')} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>{'150 km - 600 km'}</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} name="r600" value={params?.precioKm?.r600} onChange={(e) => handleChange(e, 'precioKm')} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>{'> 600 km'}</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} name="rm" value={params?.precioKm?.rm} onChange={(e) => handleChange(e, 'precioKm')} />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col md={6} lg={4} className="p-4">
                            <Form noValidate>
                                <div className="fs-5 border-bottom">Precios x M3</div>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Light</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} name="light" value={params?.precioM3?.light} onChange={(e) => handleChange(e, 'precioM3')} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Premium</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} name="premium" value={params?.precioM3?.premium} onChange={(e) => handleChange(e, 'precioM3')} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Full</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} name="full" value={params?.precioM3?.full} onChange={(e) => handleChange(e, 'precioM3')} />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col md={6} lg={4} className="p-4">
                            <Form noValidate>
                                <div className="fs-5 border-bottom">Precios Cajas</div>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Arriendo</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} name="cajaArr" value={params?.precioCaja?.cajaArr} onChange={(e) => handleChange(e, 'precioCaja')} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Embalaje</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} name="cajaEmb" value={params?.precioCaja?.cajaEmb} onChange={(e) => handleChange(e, 'precioCaja')} />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col md={6} lg={4} className="p-4">
                            <Form noValidate>
                                <div className="fs-5 border-bottom">Factor día semana</div>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Lun</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} step={0.01} name="lun" value={params?.precioDia?.lun} onChange={(e) => handleChange(e, 'precioDia')} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Mar</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} step={0.01} name="mar" value={params?.precioDia?.mar} onChange={(e) => handleChange(e, 'precioDia')} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Mie</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} step={0.01} name="mie" value={params?.precioDia?.mie} onChange={(e) => handleChange(e, 'precioDia')} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Jue</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} step={0.01} name="jue" value={params?.precioDia?.jue} onChange={(e) => handleChange(e, 'precioDia')} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Vie</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} step={0.01} name="vie" value={params?.precioDia?.vie} onChange={(e) => handleChange(e, 'precioDia')} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Sab</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} step={0.01} name="sab" value={params?.precioDia?.sab} onChange={(e) => handleChange(e, 'precioDia')} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="my-3">
                                    <Form.Label column>Dom</Form.Label>
                                    <Col>
                                        <Form.Control type="number" min={0} step={0.01} name="dom" value={params?.precioDia?.dom} onChange={(e) => handleChange(e, 'precioDia')} />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col xs={12}>
                            <Form noValidate>
                                <div className="fs-5 border-bottom">Tabla superficies</div>
                                <Table responsive className="my-3">
                                    <thead>
                                        <tr className="text-nowrap">
                                            <th>Superficie</th>
                                            <th>Volumen</th>
                                            <th>Unidad de trabajo</th>
                                            <th>Cajas premium</th>
                                            <th>Cajas full</th>
                                            <th>N° camiones</th>
                                            <th>Camión</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            params.superficie.map((d, i) => (
                                                <tr key={i}>
                                                    <th className="fw-light text-nowrap">{superfKeys[d.superf]}</th>
                                                    <th>
                                                        <Form.Control type="number" size="sm" min={0} step={1} name="volumen" value={d.volumen} onChange={(e) => handleTableChange(e, d.superf)} />
                                                    </th>
                                                    <th>
                                                        <Form.Control type="number" size="sm" min={0} step={1} name="unidTrab" value={d.unidTrab} onChange={(e) => handleTableChange(e, d.superf)} />
                                                    </th>
                                                    <th>
                                                        <Form.Control type="number" size="sm" min={0} step={1} name="cajaPrem" value={d.cajaPrem} onChange={(e) => handleTableChange(e, d.superf)} />
                                                    </th>
                                                    <th>
                                                        <Form.Control type="number" size="sm" min={0} step={1} name="cajaFull" value={d.cajaFull} onChange={(e) => handleTableChange(e, d.superf)} />
                                                    </th>
                                                    <th>
                                                        <Form.Control type="number" size="sm" min={0} step={1} name="nCams" value={d.nCams} onChange={(e) => handleTableChange(e, d.superf)} />
                                                    </th>
                                                    <th>
                                                        <Form.Control type="text" size="sm" name="cam" value={d.cam} onChange={(e) => handleTableChange(e, d.superf)} />
                                                    </th>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Form>
                        </Col>
                    </Row>
                </Container>}
        </>
    );
}

export default Parametros;