import { Form } from "react-bootstrap";
import { usePlacesWidget } from "react-google-autocomplete";

const DirForm = ({ handleChange }) => {

    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_MAP_APIKEY,
        onPlaceSelected: (place) => {
            console.log(place)
            handleChange(place)
        },
        options: {
            componentRestrictions: { country: "cl" },
            types: ['address'],
            fields: ['place_id', 'formatted_address']
        }
    });
    return (
        <Form.Control required ref={ref} placeholder="Ingresa dirección" />
    );
}

export default DirForm;