import { useEffect, useState } from "react";
import { Button, FloatingLabel, Form, Row } from "react-bootstrap";
import { usePlacesWidget } from "react-google-autocomplete";
import TagManager from "react-gtm-module";


const Cotizador4 = ({ setStep, input, setInput }) => {

    const [errors, setErrors] = useState({ tipoVivDes: !input.tipoVivDes, locDes: !input.locDes })
    const [validar, setValidar] = useState(false)

    const { ref: refDes } = usePlacesWidget({
        apiKey: process.env.REACT_APP_MAP_APIKEY,
        onPlaceSelected: (place) => {
            console.log(place)
            setInput(i => ({ ...i, dirDes: place.formatted_address, locDes: place.place_id }))
            setErrors(e => ({ ...e, locDes: !place.place_id }))
        },
        options: {
            componentRestrictions: { country: "cl" },
            types: ['address'],
            fields: ['place_id', 'formatted_address']
        }
    });

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setInput(i => ({ ...i, [name]: value }))
        if (e.target.required) setErrors(e => ({ ...e, [name]: !value }))
    }

    const handleSubmit = (e) => {
        setValidar(true)
        if (Object.values(errors).filter(Boolean).length === 0) setStep(s => s + 1)
    }

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'moving_origin',
                ecommerce: {
                    currency: 'CLP',
                    type: input.tipoVivOri,
                    address: input.dirOri,
                    commune: input.dirOri.split(',').reverse()[2],
                    surface: input.supViv,
                    room: parseInt(input.nroDorm)
                }
            }
        })
    }, [])

    return (
        <Row className="justify-content-center align-items-center min-vh-100">
            <Form noValidate className="form-max" >
                <Form.Group className="my-5">
                    <FloatingLabel controlId="form-tipoVivDes" label="Tipo de vivienda de destino">
                        <Form.Select required name="tipoVivDes" value={input.tipoVivDes} onChange={handleChange} isValid={validar && !errors.tipoVivDes} isInvalid={validar && errors.tipoVivDes}>
                            <option value="">...</option>
                            <option value="casa">Casa</option>
                            <option value="depaconAsc">Departamento con ascensor</option>
                            <option value="depasinAsc">Departamento sin ascensor</option>
                            <option value="bodega">Bodega</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" tooltip className="text-white">Selecciona el tipo de vivienda</Form.Control.Feedback>
                    </FloatingLabel>
                </Form.Group>
                <Form.Group className="my-5">
                    <FloatingLabel controlId="form-dirDes" label="Dirección de destino">
                        <Form.Control required ref={refDes} placeholder="Ingresa dirección" isValid={validar && !errors.locDes} isInvalid={validar && errors.locDes} />
                        <Form.Control.Feedback type="invalid" tooltip className="text-white">Ingresa tu dirección de destino</Form.Control.Feedback>
                    </FloatingLabel>
                </Form.Group>
                <div className="text-center">
                    <Button onClick={handleSubmit} className="px-5 py-2 text-white fw-bold">
                        Continuar
                    </Button>
                </div>
            </Form>
        </Row>
    );
}

export default Cotizador4;