import { Col, Image, Row } from "react-bootstrap";
import { format, parseISO, add } from 'date-fns'
import { es } from 'date-fns/locale'

const ResumenMudanza = ({ input }) => {
    return (
        <>
            <Row xs={1} lg={3} className="border border-1 rounded">
                <Col className="d-flex flex-column justify-content-center py-3">
                    <div className="small fw-bold">Origen</div>
                    <div className="small my-2">{input.dirOri}</div>
                </Col>
                <Col className="d-flex justify-content-between align-items-center py-3">
                    <Image fluid src='/arrow.png' style={{ height: '3rem' }} />
                    <div>
                        <Image fluid src='/delivery.png' style={{ height: '4rem' }} />
                        <div className="d-flex">
                            <div className="px-3">
                                <div className="small fw-bold">{input.volumen} m<sup>3</sup></div>
                                <div className="small">Volumen*</div>
                            </div>
                            <div className="px-3">
                                <div className="small fw-bold">{format(parseISO(input.fecha), 'dd MMMM yyyy', { locale: es })}</div>
                                <div className="small">Fecha</div>
                            </div>
                        </div>
                    </div>
                    <Image fluid src='/arrow.png' style={{ height: '3rem' }} />
                </Col>
                <Col className="d-flex flex-column justify-content-center py-3">
                    <div className="small fw-bold">Destino</div>
                    <div className="small my-2">{input.dirDes}</div>
                </Col>
            </Row>
            <div className="small my-2">
                * Volumen estimado a partir de superficie de la vivienda ({input.supViv} m<sup>2</sup>). <br />
                ** Esta cotización es válida hasta el {format(add(parseISO(input.createdAt), { days: 5 }), "dd 'de' MMMM 'de' yyyy", { locale: es })}.
            </div>
        </>
    );
}

export default ResumenMudanza;