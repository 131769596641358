import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button, Form, Image, Spinner } from "react-bootstrap";
import TagManager from "react-gtm-module";

const url = 'https://api.whatsapp.com/send?phone=56957860076&text=Hola!%20Me%20interesa%20hacer%20mi%20mudanza%20con%20ustedes!'

const Price = ({ input, modo }) => {
    const [cajas, setCajas] = useState(input.cajas?.[modo] || 0)
    const [dscto, setDscto] = useState(false)
    const [precio, setprecio] = useState(Math.round(input.precios?.[modo] + (input.cajas?.[modo] || 0) * input.params.precioCaja[modo === 'light' ? 'cajaArr' : 'cajaEmb'] * (1 - input?.creado || 0)))
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        const value = e.target.value
        setCajas(value)
    }

    const handleDscto = (e) => {
        setDscto(e.target.checked)
    }

    const handleReserve = async () => {
        setLoading(true)
        try {
            await API.post('apid46427ec', '/reserva', { body: { id: input.id, dealID: input.dealID, finalPrecio: precio, finalModo: modo, finalCajas: cajas } })

            TagManager.dataLayer({
                dataLayer: {
                    event: 'purchase', ecommerce: {
                        currency: 'CLP',
                        items: [{
                            item_id: input.id,
                            item_name: `Mudanza ${modo}`,
                            item_brand: 'Mudanzasmayor',
                            item_category: 'Mudanza',
                            item_variant: modo,
                            price: precio / (1 - dscto * .05),
                            discount: dscto * .05 * precio / (1 - dscto * .05),
                            currency: 'CLP',
                            quantity: 1
                        }],
                        value: precio
                    }
                }
            })
            if (input?.creado === 1) {
                window.location.href = input.mpUrl
            } else {
                window.location.href = url
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (input.finalModo == modo) {
            setprecio(input.finalPrecio)
            setCajas(input.finalCajas)
        } else {
            setprecio(Math.round((input.precios?.[modo] + cajas * input.params.precioCaja[modo === 'light' ? 'cajaArr' : 'cajaEmb'] * (1 - input?.creado || 0)) * (1 - dscto * .05)))
        }
    }, [cajas, dscto])

    return (
        <div className="price-card text-center my-3">
            <div>
                <div className="mx-3 py-3 fs-3">Mudanza <span className="text-uppercase">{modo}</span></div>
                <Image roundedCircle fluid src={modo === 'premium' ? '/heart.png' : modo === 'full' ? '/star.png' : '/empty.png'} className="icon" />
            </div>
            <div className="border-bottom py-3">
                <small className="fw-bold">Armado y Desarmado</small>
                <div>Camas y Muebles</div>
            </div>
            <div className="border-bottom py-3">
                <small className="fw-bold">Protección de Muebles</small>
                <div>Frazadas + Film + Otros</div>
            </div>
            <div className="border-bottom py-3">
                <small className="fw-bold">Embalaje de Cajas</small>
                <div className={modo !== 'light' ? 'text-success fw-bold' : undefined}>
                    {modo === 'full' ? 'Todo Incluido' : modo === 'premium' ? 'Todo Incluido' : 'No Incluido'}
                </div>
            </div>
            {modo !== 'light' && (
                <div className="border-bottom py-3">
                    <small className="fw-bold">N° de Cajas</small>
                    <div className="px-5">
                        <Form.Select name="cajas" disabled={input?.creado} value={cajas} onChange={handleChange} size="sm" className="text-center px-0">
                            {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220, 230, 240].map((i) => (
                                <option value={i} key={`option-${i}`}>{i}</option>
                            ))}
                        </Form.Select>
                    </div>
                </div>
            )}
            {modo === 'light' && (
                <div className="border-bottom mx-4 py-3">
                    <small className="fw-bold">Arriendo Cajas</small>
                    <div className="px-5">
                        <Form.Select name="cajas" disabled={input?.creado} value={cajas} onChange={handleChange} size="sm" className="text-center px-0">
                            {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220, 230, 240].map((i) => (
                                <option value={i} key={`option-${i}`}>{i}</option>
                            ))}
                        </Form.Select>
                    </div>
                </div>
            )}
            <div className="border-bottom py-3">
                <small className="fw-bold">Desembalaje de Cajas</small>
                <div className={modo === 'full' ? 'text-success fw-bold' : undefined}>
                    {modo === 'full' ? 'Incluido' : 'No Incluido'}
                </div>
            </div>
            <div className="my-3">
                <div className="px-5 small">
                    <Form.Check type="switch" label="Aplica 5% de descuento" onChange={handleDscto} disabled={input.estado == 1} />
                </div>
            </div>
            <div className="my-3">
                <div className="fw-bold text-secondary fs-3">
                    $ {precio?.toLocaleString('es-CL')} + IVA
                </div>
            </div>
            <div className="my-3">
                <Button disabled={loading || input.estado == 1} onClick={handleReserve} className="px-5 py-2 text-white text-uppercase fw-bold">
                    {loading ? <><Spinner as="span" size="sm" animation="border" /> Reservando...</> :
                        input.estado == 1 && input.finalModo == modo ? <>Reservado</> : <>Me Interesa</>
                    }
                </Button>
            </div>
            <div className="text-center my-3 px-3 small">
                Quedan <span className="text-primary fw-bold">{modo === 'light' ? '4' : modo === 'premium' ? '2' : '1'} cupo/s disponibles </span> para la fecha que necesitas!
            </div>
        </div>
    );
}

export default Price;
