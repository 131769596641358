import { useState } from "react";
import { API } from "aws-amplify";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Cotizador1 from "../components/Pasos Cotizador/Cotizador-p1";
import Cotizador2 from "../components/Pasos Cotizador/Cotizador-p2";
import Cotizador3 from "../components/Pasos Cotizador/Cotizador-p3";
import Cotizador4 from "../components/Pasos Cotizador/Cotizador-p4";
import Cotizador5 from "../components/Pasos Cotizador/Cotizador-p5";
import Loader from "../components/Loader";
import ProgressBar from "../components/ProgressBar";
import ArrowBack from "../components/ArrowBack";

const Cotizador = () => {
    const [step, setStep] = useState(1)
    const [input, setInput] = useState({})
    const [error, setError] = useState(false)

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const submit = async () => {
        setLoading(true)
        try {
            const { id } = await API.post('apid46427ec', '/cotizador', { body: input })
            if (!!id) navigate(`/cotizacion/${id}`)
        } catch (error) {
            setError(true)
            setLoading(false)
        }
    }

    return (
        loading ? <Loader /> :
            <>
                <ProgressBar width={100 * (step - 1) / 5} />
                <Container>
                    {
                        step === 1 && (
                            <Cotizador1 {...{ setStep }} />
                        )
                    }
                    {
                        step === 2 && (
                            <Cotizador2 {...{ setStep, input, setInput }} />
                        )
                    }
                    {
                        step === 3 && (
                            <Cotizador3 {...{ setStep, input, setInput }} />
                        )
                    }
                    {
                        step === 4 && (
                            <Cotizador4 {...{ setStep, input, setInput }} />
                        )
                    }
                    {
                        step === 5 && (
                            <Cotizador5 {...{ input, setInput, submit, error }} />
                        )
                    }
                    {
                        step >=3 && (
                            <ArrowBack setStep={setStep} />
                        )
                    }
                </Container>
            </>
    );
}

export default Cotizador;
