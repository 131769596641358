import { Spinner } from "react-bootstrap";

const Loader = () => {
    return (
        <div className="d-flex justify-content-center align-items-center min-vh-100">
            <div className="d-block text-center">
                <Spinner animation="border" variant="primary" />
                <div className="my-3">
                    Estamos buscando el mejor precio para tu Mudanza...
                </div>
            </div>
        </div>
    );
}

export default Loader;