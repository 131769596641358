import { Col, Container, Image, Ratio, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLoaderData } from "react-router-dom";
// import { initMercadoPago } from '@mercadopago/sdk-react'

import AccordionCustom from "../components/Accordion-custom";
import Price from "../components/Price";
import ResumenMudanza from "../components/ResumenMudanza";

// initMercadoPago('TEST-96e7a63d-a5dd-44f7-8ec8-1ab4b2949835', {locale: 'es-CL'});

const Cotizacion = () => {
    const input = useLoaderData()

    return (
        !!input?.id ?
            <Container>
                <Helmet>
                    <script defer async src='https://cdn.trustindex.io/loader.js?25882dd10a3331843856729d4e5'></script>
                    <title>Cotización</title>
                </Helmet>
                <div className="text-center my-5">
                    <Image roundedCircle fluid src="/foto.png" className="cotizacion-foto my-3" />
                    <div>
                        {input.nombre}, te recomiendo nuestro servicio FULL.
                        <br />
                        Nosotros nos haremos cargo de embalar loza, adornos, libros y ropa. Ganas tiempo y evitas hacer este trabajo ;)
                    </div>
                </div>
                <Row xs={1} sm={2} lg={3} >
                    <Col>
                        <Price modo='light' input={input} />
                    </Col>
                    <Col>
                        <Price modo='premium' input={input} />
                    </Col>
                    <Col>
                        <Price modo='full' input={input} />
                    </Col>
                </Row>
                <div className="text-center my-2">
                    ¡Estás en buenas manos! <br />
                    Todos nuestros servicios incluyen una planificación personalizada del embalaje, protección,
                    cuidado y traslado de todos tus bienes. Atienden sus dueños y garantizamos hasta el 100% del valor del servicio.
                </div>
                <div className="my-5 text-center">
                    <div className="fs-5 my-2">Resumen de tu Mudanza</div>
                    <ResumenMudanza input={input} />
                </div>
                <div className="text-center my-5">
                    ¡En este momento <span className="text-primary fw-bold">{Math.floor(Math.random() * 200)} personas </span> están cotizando nuestros servicios!
                </div>
                <div className="d-flex justify-content-center my-5">
                    <Ratio aspectRatio="16x9" className="video">
                        <iframe src={`https://www.youtube.com/embed/${process.env.REACT_APP_VIDEO_URL}?controls=0`} frameborder="0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"></iframe>
                    </Ratio>
                </div>
                <div src='https://cdn.trustindex.io/loader.js?25882dd10a3331843856729d4e5' className="text-center my-5">
                </div>
                <div className="my-5">
                    <AccordionCustom />
                </div>
                <div className="text-center small my-5">
                    Revisa aquí las <a href="https://www.mudanzasmayor.cl/condicionesdelservicio"><b>condiciones del servicio</b></a>.
                </div>
            </Container> :
            <Container>
                <div className="text-center my-5">
                    Cotización no encontrada :(
                </div>
            </Container>
    );
}

export default Cotizacion;