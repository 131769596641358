/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "apid46427ec",
            "endpoint": "https://03d4mfl5be.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "apinotsmp",
            "endpoint": "https://rfmr5k79xf.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ziqdbcsukra63kfa4iqwn3337i.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "us-east-2:e312e6bd-9223-48d9-a85c-9938942da74b",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_SNzLnVM6O",
    "aws_user_pools_web_client_id": "26k0fcvbq8qgv23kt888fffkul",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
