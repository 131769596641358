import { useEffect, useState } from "react";
import { Button, FloatingLabel, Form, Row } from "react-bootstrap";
import { formatRut, validateEmail, validateRut, validateTel } from "../../functions";
import TagManager from "react-gtm-module";


const Cotizador5 = ({ input, setInput, submit, error }) => {

    const [errors, setErrors] = useState({ nombre: true, rut: true, mail: true, tel: true })
    const [validar, setValidar] = useState(false)

    const [rut, setRut] = useState('')

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setInput(i => ({ ...i, [name]: value }))
        if (e.target.required) {
            var errorValue = !value
            if (name === 'mail') errorValue = !validateEmail(value)
            if (name === 'tel') errorValue = !validateTel(value)
            setErrors(e => ({ ...e, [name]: errorValue }))
        }
    }

    const handleSubmit = async () => {
        setValidar(true)
        if (Object.values(errors).filter(Boolean).length === 0) {
            TagManager.dataLayer({ dataLayer: { event: 'generate_lead', ecommerce: { currency: 'CLP' }  } })
            submit()
        }
    }

    useEffect(() => {
        if (rut) {
            setInput(i => ({ ...i, rut: formatRut(rut) }))
            setErrors(e => ({ ...e, rut: !validateRut(rut) }))
        }
        setErrors(e => ({ ...e, fecha: !rut }))
    }, [rut])

    useEffect(() => {
        if (!!error) setErrors({ nombre: false, rut: false, mail: false, tel: false })
    }, [error])

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'moving_destination', ecommerce: {
                    currency: 'CLP',
                    type: input.tipoVivDes,
                    address: input.dirDes,
                    commune: input.dirDes.split(',').reverse()[2]
                }
            }
        })
    }, [])

    return (
        <Row className="justify-content-center align-items-center min-vh-100">
            <Form noValidate className="form-max" >
                <Form.Group className="my-5">
                    <FloatingLabel label="Nombre" controlId="form-nombre">
                        <Form.Control required name="nombre" value={input.nombre} onChange={handleChange} placeholder="Nombre" isValid={validar && !errors.nombre} isInvalid={validar && errors.nombre} />
                        <Form.Control.Feedback type="invalid" tooltip className="text-white">Ingresa tu nombre</Form.Control.Feedback>
                    </FloatingLabel>
                </Form.Group>
                <Form.Group className="my-5">
                    <FloatingLabel label="RUT" controlId="form-rut">
                        <Form.Control required name="rut" value={rut} onChange={r => setRut(r.target.value)} placeholder="12345678-9" isValid={validar && !errors.rut} isInvalid={validar && errors.rut} />
                        <Form.Control.Feedback type="invalid" tooltip className="text-white">Ingresa un RUT válido</Form.Control.Feedback>
                    </FloatingLabel>
                </Form.Group>
                <Form.Group className="my-5">
                    <FloatingLabel label="Mail" controlId="form-mail">
                        <Form.Control required type="email" name="mail" value={input.mail} onChange={handleChange} placeholder="Mail" isValid={validar && !errors.mail} isInvalid={validar && errors.mail} />
                        <Form.Control.Feedback type="invalid" tooltip className="text-white">Ingresa tu email con el formato email@email.com</Form.Control.Feedback>
                    </FloatingLabel>
                </Form.Group>
                <Form.Group className="my-5">
                    <FloatingLabel label="Teléfono" controlId="form-tel">
                        <Form.Control required type="tel" name="tel" value={input.tel} onChange={handleChange} placeholder="Teléfono" isValid={validar && !errors.tel} isInvalid={validar && errors.tel} />
                        <Form.Control.Feedback type="invalid" tooltip className="text-white">Ingresa tu teléfono</Form.Control.Feedback>
                    </FloatingLabel>
                </Form.Group>
                <Form.Group className="my-5">
                    <FloatingLabel label="Comentarios" controlId="form-coment">
                        <Form.Control as="textarea" name="coment" value={input.coment} onChange={handleChange} placeholder="Comentarios" />
                    </FloatingLabel>
                </Form.Group>
                <div className="text-center">
                    <Button onClick={handleSubmit} className="px-5 py-2 text-white fw-bold" >
                        Ver Precios
                    </Button>
                    {!!error &&
                        <div className="small">Ha ocurrido un error, intente nuevamente.</div>
                    }
                </div>
            </Form>
        </Row>
    );
}

export default Cotizador5;