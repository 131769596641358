export const getSolicitud = /* GraphQL */ `
  query GetSolicitud($id: ID!) {
    getSolicitud(id: $id) {
      fecha
      dirOri
      dirDes
      dist
      tipoVivOri
      tipoVivDes
      supViv
      coment
      params {
        precioCaja {
          cajaEmb
          cajaArr
        }
      }
      volumen
      precios {
        light
        premium
        full
      }
      cajas {
        premium
        full
      }
      nombre
      dealID
      mpPref
      mpUrl
      estado
      creado
      finalModo
      finalCajas
      finalPrecio
      id
      createdAt
      updatedAt
    }
  }
`;

export const paramsBydate = /* GraphQL */ `
  query ParamsBydate(
    $tipo: Int!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelParametrosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paramsBydate(
      tipo: $tipo
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tipo
        precioM3 {
          light
          premium
          full
        }
        precioCaja {
          cajaEmb
          cajaArr
        }
        precioKm {
          r150
          r600
          rm
        }
        precioViv {
          casa
          depaconAsc
          depasinAsc
          bodega
        }
        precioDia {
          lun
          mar
          mie
          jue
          vie
          sab
          dom
        }
        superficie {
          superf
          volumen
          unidTrab
          cajaPrem
          cajaFull
          nCams
          cam
        }
      }
      nextToken
    }
  }
`;

export const createParametros = /* GraphQL */ `
  mutation CreateParametros(
    $input: CreateParametrosInput!
    $condition: ModelParametrosConditionInput
  ) {
    createParametros(input: $input, condition: $condition) {
      id
    }
  }
`;