import { useEffect, useState } from "react";
import { Button, FloatingLabel, Form, Row } from "react-bootstrap";
import { usePlacesWidget } from "react-google-autocomplete";

import Slider from "rc-slider";
import 'rc-slider/assets/index.css';
import TagManager from "react-gtm-module";

const marks = Array.from({ length: 6 }, (_, i) => (i + 1) * 50).reduce((a, v) => ({ ...a, [v]: <small>{v}m<sup>2</sup></small> }), {})


const Cotizador3 = ({ setStep, input, setInput }) => {

    const [errors, setErrors] = useState({ tipoVivOri: !input.tipoVivOri, locOri: !input.locOri, supViv: !input.supViv, nroDorm: !input.nroDorm })
    const [validar, setValidar] = useState(false)

    const { ref: refOri } = usePlacesWidget({
        apiKey: process.env.REACT_APP_MAP_APIKEY,
        onPlaceSelected: (place) => {
            console.log(place)
            setInput(i => ({ ...i, dirOri: place.formatted_address, locOri: place.place_id }))
            setErrors(e => ({ ...e, locOri: !place.place_id }))
        },
        options: {
            componentRestrictions: { country: "cl" },
            types: ['address'],
            fields: ['place_id', 'formatted_address']
        }
    });

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setInput(i => ({ ...i, [name]: value }))
        if (e.target.required) setErrors(e => ({ ...e, [name]: !value }))
    }
    const handleChangeSlider = (v) => {
        setInput(i => ({ ...i, supViv: v }))
        setErrors(e => ({ ...e, supViv: !v }))
    }

    const handleSubmit = (e) => {
        setValidar(true)
        if (Object.values(errors).filter(Boolean).length === 0) setStep(s => s + 1)
    }

    useEffect(() => {
        TagManager.dataLayer({ dataLayer: { event: 'moving_date', ecommerce: { currency: 'CLP', date: input.fecha } } })
    }, [])

    return (
        <Row className="justify-content-center align-items-center min-vh-100">
            <Form noValidate className="form-max" >
                <Form.Group className="my-5">
                    <FloatingLabel controlId="form-tipoVivOri" label="Tipo de vivienda de origen">
                        <Form.Select required name="tipoVivOri" value={input.tipoVivOri} onChange={handleChange} isValid={validar && !errors.tipoVivOri} isInvalid={validar && errors.tipoVivOri}>
                            <option value="">...</option>
                            <option value="casa">Casa</option>
                            <option value="depaconAsc">Departamento con ascensor</option>
                            <option value="depasinAsc">Departamento sin ascensor</option>
                            <option value="bodega">Bodega</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" tooltip className="text-white">Selecciona el tipo de vivienda</Form.Control.Feedback>
                    </FloatingLabel>
                </Form.Group>
                <Form.Group className="my-5">
                    <FloatingLabel controlId="form-dirOri" label="Dirección de origen">
                        <Form.Control required ref={refOri} placeholder="Ingresa dirección" isValid={validar && !errors.locOri} isInvalid={validar && errors.locOri} />
                        <Form.Control.Feedback type="invalid" tooltip className="text-white">Ingresa tu dirección de origen</Form.Control.Feedback>
                    </FloatingLabel>
                </Form.Group>
                <Form.Group className="mt-3 mb-5 pb-4">
                    <div className="position-relative">
                        <Form.Label>Superficie de la vivienda</Form.Label>
                        <div className="d-flex">
                            <div className="small text-center" style={{ width: '20%' }}>{input.supViv || 0}m<sup>2</sup></div>
                            <Slider min={10} max={300} step={5} marks={marks} value={input.supViv} onChange={handleChangeSlider} className="" />
                        </div>
                        {validar && errors.supViv && <div className="invalid-tooltip d-block mt-4 text-white">Selecciona la superficie de la vivienda</div>}
                    </div>
                </Form.Group>
                <Form.Group className="my-5">
                    <FloatingLabel controlId="form-nroDorm" label="N° de Dormitorios">
                        <Form.Control required type="number" min={0} max={10} name="nroDorm" value={input.nroDorm} onChange={handleChange} isValid={validar && !errors.nroDorm} isInvalid={validar && errors.nroDorm} />
                        <Form.Control.Feedback type="invalid" tooltip className="text-white">Ingresa la cantidad de Dormitorios</Form.Control.Feedback>
                    </FloatingLabel>
                </Form.Group>
                <div className="text-center">
                    <Button onClick={handleSubmit} className="px-5 py-2 text-white fw-bold" >
                        Continuar
                    </Button>
                </div>
            </Form>
        </Row>
    );
}

export default Cotizador3;