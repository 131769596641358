import { redirect } from "react-router-dom"
import { API } from "aws-amplify"
import { getSolicitud } from "./graphql/customQueries"


export const getCotizacion = async ({ params }) => {
    try {
        const { data: { getSolicitud: data } } = await API.graphql({ query: getSolicitud, variables: { id: params.id } })
        return data
    } catch (error) {
        try {
            const { data: { getSolicitud: data } } = await API.graphql({ query: getSolicitud, variables: { id: params.id }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            return data
        } catch (error) {
            redirect('/')
        }
    }
}

export const validateEmail = (value) => {
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
}

export const validateRut = (value) => {
    // if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(value)) return false
    const rut = value.replace(/^0+|[^0-9kK]+/g, '').toLowerCase()

    let rn = parseInt(rut.slice(0, -1), 10)
    let dv = rut.slice(-1)
    var m = 0, s = 1
    while (rn > 0) {
        s = (s + (rn % 10) * (9 - (m++ % 6))) % 11
        rn = Math.floor(rn / 10)
    }
    const dvv = s > 0 ? '' + (s - 1) : 'k'
    return (dvv === dv)
}
export const formatRut = (value) => {
    const rut = value.replace(/^0+|[^0-9kK]+/g, '').toLowerCase()
    return rut.slice(0, -1) + '-' + rut.substr(rut.length - 1)
}

export const validateTel = (value) => {
    return /^(\+)?[0-9]{9,11}$/g.test(value)
}